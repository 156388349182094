import React from 'react';
import { Link } from 'gatsby';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        from iCalendar (ics) files. Here's how:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button on the Calendar Data
          Sources step and select the{' '}
          <strong>Local -&gt; iCalendar File </strong>option.
        </li>
        <li>
          Select the desired iCalendar file and click the <strong>Open</strong>{' '}
          button. Note, the file location is where PrintableCal will look for
          data each time a calendar is generated. If you move the file to a
          different folder, you'll need to remove it from PrintableCal's list of
          calendar data sources and re-add it.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/iCalendar-File"
      commentsId="commentsplus_post_146_489"
      title="Print Calendars from iCalendar (ics) Files"
      description="PrintableCal can create printable calendars from iCalendar (ics) files."
      keywords="print ics, print icalendar, print ical, print events, print calendar, calendar template, printable calendar, Excel calendar, Word calendar, import ics, import icalendar"
      content={content}
    />
  );
};

export default Documentation;
